import './assets/css/settingsLS.css'
import './assets/css/reset.css'
import './assets/css/base.css'
import './i18n.ts'

import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { GlobalContextProvider } from './components/GlobalContext.tsx'
import TextGen from './components/TextGen.tsx'
import ErrorPage from './ErrorPage.tsx'

// eslint-disable-next-line react-refresh/only-export-components
const History = React.lazy(() => import('./components/admin/History.tsx'))
// eslint-disable-next-line react-refresh/only-export-components
const Privacy = React.lazy(() => import('./components/Privacy.tsx'))
// eslint-disable-next-line react-refresh/only-export-components
const Bot = React.lazy(() => import('./components/Bot.tsx'))
// eslint-disable-next-line react-refresh/only-export-components

const router = createBrowserRouter([
  { path: '/privacy', element: <Privacy /> },
  { path: '/bot', element: <Bot /> },
  {
    path: '/admin/history/user/:userId',
    element: <History />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/admin/history/cid/:cId',
    element: <History />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/admin/history/:id?',
    element: <History />,
    errorElement: <ErrorPage />,
  },
  { path: '/*', element: <TextGen />, errorElement: <ErrorPage /> },
])

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <GlobalContextProvider>
      <RouterProvider router={router} />
    </GlobalContextProvider>
  </React.StrictMode>,
)
