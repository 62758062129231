import { diffWords } from 'diff'

const DiffWords = (props: { a: string; b: string }) => {
  const dif = diffWords(props.a, props.b, { ignoreWhitespace: true })

  /*const diff2 = dif.reduce((acc, cur, i) => {
    acc.push(cur)
    return acc
  }, [] as Change[])*/
  return dif.map((part, index) => {
    if (!part.value || part.value.match(/^\n+$/)) {
      return <></>
    }
    const color = part.added ? 'green' : part.removed ? 'darkred' : 'var(--font-color)'

    return (
      <span
        key={index}
        style={{ color, textDecoration: part.removed ? 'line-through' : undefined, textDecorationThickness: '1px' }}
      >
        {part.value}
      </span>
    )
  })
}
export default DiffWords
