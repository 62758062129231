import '../assets/css/layout.css'
import '../assets/css/button.css'
import '../assets/css/box.css'
import '../assets/css/question.css'
import '../assets/css/accordion.css'
import '../assets/css/illustration.css'
import '../assets/css/tooltip.css'
import '../assets/css/textgen.css'

import { useAccordionProvider } from '@szhsin/react-accordion'
//import tokenizer from 'gpt-tokenizer'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import TextareaAutosize from 'react-textarea-autosize'

import { ArrowRight } from '../ArrowRight.tsx'
import { Copy } from '../Copy.tsx'
import { DEFAULT_SHORT_NAME } from '../helpers.ts'
import { TypingMessageQueue } from '../TypingMessageQueue.ts'
import { EventSourceMessage, useEventSource } from '../useEventSource.ts'
import DiffWords from './DiffWords.tsx'
import { ExtendedFeedback } from './ExtendedFeedback.tsx'
import { Footer } from './Footer.tsx'
import GlobalContext from './GlobalContext.tsx'
import { Header } from './Header.tsx'
import MyMarkdown from './Markdown.tsx'
const EVENT_SOURCE_NAME = 'default'

const MODE = [
  { id: 'winti', nativeName: 'Nach den Richtlinien der Stadt Winterthur' },
  { id: 'es', nativeName: 'Einfache Sprache (Richtlinien Kt. ZH)' },
  { id: 'ls', nativeName: 'Leichte Sprache (Richtlinien Kt. ZH)' },
]
/*
// the npm package is huge...
const countTokens = (content: string): number => {
  return tokenizer.encode(content).length
}*/

const TextGen = () => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')
  const { mainLanguage, org, host } = useContext(GlobalContext)
  const [userId, setUserId] = useState<string | null>(localStorage.getItem('uID') || sessionStorage.getItem('uID'))
  const [queryResult, setQueryResult] = useState<string>('')
  const [sseUri, setSseUri] = useState('')
  const [queryValue, setQueryValue] = useState('')
  const { close: eventSourceClose, start: eventSourceStart } = useEventSource()
  const [requestId, setRequestId] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [model, _setModel] = useState<string>(DEFAULT_SHORT_NAME.MODEL_4o_mini_8k)
  const [mode, setMode] = useState<string>('winti')
  const [u15y, setU15y] = useState<string | null>(null)
  const [copied, setCopied] = useState(false)
  const feedbackRef = useRef(null)
  const hintRef = useRef(null)
  const questionRef = useRef(null)
  const providerValue = useAccordionProvider({
    allowMultiple: true,
    transition: true,
    transitionTimeout: 450,
  })
  const setNewEventSource = useCallback(
    (queryValue: string, model: string, id: string) => {
      if (queryValue && org) {
        const source = `${host}/${org}/textgen/generate/?id=${id}&key=X9hL4Gp5W2D7eRtF&${userId ? `&uID=${userId}` : ''}&lang=${mainLanguage}&modelId=${model}&mode=${mode}`
        setSseUri(source)
      }
    },
    [org, host, userId, mainLanguage, mode],
  )

  const parseMessageFromEventSource = useCallback(
    (parsedData: Record<string, any>, queue: TypingMessageQueue) => {
      try {
        if (parsedData.response) {
          const answerEnd = () => {
            setIsLoading(false)
          }
          if (parsedData.response === '__THIS_IS_THE_ANSWER_END__') {
            answerEnd()
            return
          }

          if (parsedData.response === '__THIS_IS_THE_END__') {
            eventSourceClose(EVENT_SOURCE_NAME)
            queue.enqueue(parsedData.response)
            queue.setThisIsTheEnd()
            setQueryValue('')
            answerEnd()
            return
          }
          if (parsedData.response === '__CLR__') {
            queue.enqueue(parsedData.response)
            return
          }

          queue.enqueue(parsedData.response)
          /*setQueryResult(queryResult => {
              return queryResult.concat(parsedData.response)
            })*/
        }
        if (parsedData.id) {
          setRequestId(parsedData.id)
        }
      } catch (error) {
        console.log(error)
      }
    },
    [eventSourceClose],
  )

  useEffect(() => {
    fetch(host + `/${org}/log`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ referrer: document.referrer || null, uID: userId }),
    })
      .then(async response => {
        const user = await response.json()
        if (user && user.id) {
          localStorage.setItem('uID', user.id)
          setUserId(user.id)
        }
      })
      .catch(error => {
        console.log('Error Log', error)
      })
  }, [host, org, userId])

  useEffect(() => {
    try {
      if (!sseUri || !queryValue) {
        return
      }
      setIsLoading(true)

      const queue = new TypingMessageQueue(setQueryResult)
      const onerror = (e: Event) => {
        // for some strange reason, we get an error event before the server sends the __THIS_IS_THE_END__ event
        // in some cases. We don't need to show that error, after we received some links... The main result is here anyway
        // For example: Ask for "Wer ist Inka?" on WintiGPT....
        console.log('SSE Error', e)
        eventSourceClose(EVENT_SOURCE_NAME)
        setQueryValue('')
        setIsLoading(false)
      }
      const onmessage = (event: EventSourceMessage) => {
        const parsedData = JSON.parse(event.data)

        parseMessageFromEventSource(parsedData, queue)
      }
      eventSourceStart(EVENT_SOURCE_NAME, sseUri, onmessage, onerror)

      return () => {
        eventSourceClose(EVENT_SOURCE_NAME)
      }
    } catch (error) {
      console.log(error)
    }
  }, [eventSourceClose, eventSourceStart, parseMessageFromEventSource, queryValue, sseUri, t])

  const sendSubmit = (inputValue: string, model: string = '4o-8k') => {
    setCopied(false)
    const fetchData = async () => {
      fetch(`https://u15y-api.gpt.liip.ch/understandability`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ text: inputValue }),
      }).then(async response => {
        const LIMIT_HARD = 13
        const LIMIT_MEDIUM = 16
        const u15yData: {
          understandability: number
          cefr_level: string
        } = await response.json()

        const getText = () => {
          if (u15yData.understandability < LIMIT_HARD) {
            return `<p>Dein Text ist <span class="red">schwer verständlich</span>
                    (${Math.round(u15yData?.understandability * 10) / 10} von 20 Punkten). Das entspricht etwa dem Sprachniveau <span class="red">${u15yData?.cefr_level}</span>.</p>`
          }

          if (u15yData.understandability >= LIMIT_HARD && u15yData.understandability < LIMIT_MEDIUM) {
            return `<p>Dein Ausgangstext ist nur <span class="orange">mässig verständlich</span>
                    (${Math.round(u15yData?.understandability * 10) / 10} von 20 Punkten). 
                    Das entspricht etwa dem Sprachniveau <span class="orange">${u15yData?.cefr_level}</span>.</p>`
          }
          return `<p>Dein Ausgangstext ist <span class="green">gut verständlich</span>
                    (${Math.round(u15yData?.understandability * 10) / 10} von 20 Punkten). 
                    Das entspricht etwa dem Sprachniveau <span class="green">${u15yData?.cefr_level}</span>.</p>`
        }
        setU15y(getText())
      })

      const reponse = await fetch(
        host + `/${org}/textgen/store?key=X9hL4Gp5W2D7eRtF&${userId ? `&uID=${userId}` : ''}`,
        {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ query: inputValue, uID: userId }),
        },
      )

      setNewEventSource(inputValue, model, (await reponse.json()).id)
      setQueryResult(t('answers.oneMoment'))
    }
    if (inputValue.trim() === '') return
    setQueryValue(inputValue)

    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('q', inputValue)
    // document.title = `${import.meta.env.VITE_SITE_NAME}: ${inputValue}`
    fetchData()
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value)
  }
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    /*  if (props.submitDisabled) {
      return
    }*/

    sendSubmit(inputValue, model)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && event.metaKey) {
      event.preventDefault()
      handleSubmit(event)
    }
  }
  const queryResults = queryResult.split('******')
  const betterText = queryResults[0].trim()
  return (
    <div className={`layout textGen`}>
      <Header
        slogan={
          'Bitte beachten Sie, dass die Eingabe von vertraulichen, personenbezogenen oder internen Informationen in dieses Eingabefeld nicht gestattet ist.  Es liegt in Ihrer Verantwortung, die generierten Inhalte zu verwenden. Ausserdem muss das [Merkblatt](https://textmate.gpt.liip.ch/pdfs/merkblatt-kuenstliche-intelligenz.pdf) zur Nutzung von Online-KI-Generatoren beachtet werden.'
        }
        title={'Winti TextMate (alpha)'}
      />
      <main className="main">
        <div className="container" style={{ paddingTop: '1em' }}>
          <div className="question-wrapper" style={{ transform: 'none' }}>
            <form onSubmit={handleSubmit} className="question" style={{ flexFlow: 'column' }}>
              <label htmlFor="question" className="sr-only">
                {t('question.label')}
              </label>
              <div className="question__input">
                <TextareaAutosize
                  autoFocus
                  minRows={10}
                  className="question__input"
                  id="question"
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  placeholder={'Geben Sie Ihren Text hier ein.'}
                />
              </div>
              <div style={{ display: 'flex' }}>
                <button
                  type="submit"
                  tabIndex={-1}
                  className="question__button button button--no-wrap"
                  style={{ height: '2.5em', width: '13em', marginLeft: '1em' }}
                  disabled={inputValue.length === 0}
                >
                  <ArrowRight />
                  <span>Text überarbeiten</span>
                </button>
                <div className="footer__language">
                  <label htmlFor="language-switcher" className="sr-only">
                    {t('footer.selectLanguage')}
                  </label>
                  <div
                    className="select"
                    style={{
                      height: '2.5em',
                      width: '25em',
                      marginLeft: '1em',
                      marginBottom: '1em',
                      backgroundColor: 'var(--color-primary-700)',
                    }}
                  >
                    <select
                      id="language-switcher"
                      defaultValue={mainLanguage}
                      className="button"
                      onChange={e => {
                        setMode(e.target.value)
                      }}
                      style={{ backgroundColor: 'var(--color-primary-700)' }}
                    >
                      {MODE.map(model => (
                        <option key={model.id} value={model.id}>
                          {model.nativeName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <button
                  type="reset"
                  tabIndex={-1}
                  className="question__button button button--no-wrap"
                  style={{ height: '2.5em', width: '5em', marginLeft: 'auto' }}
                  disabled={inputValue.length === 0}
                  onClick={() => {
                    setQueryResult('')
                    setU15y('')
                    setInputValue('')
                  }}
                >
                  <span>Reset</span>
                </button>
              </div>
            </form>
          </div>
          <div className="resultBoxContainer">
            <div className="resultBox chatbot" translate="no" style={{ marginTop: '2em', paddingLeft: '0.5em' }}>
              {u15y && (
                <>
                  <h4>
                    Bewertung nach dem{' '}
                    <a
                      href={'https://github.com/machinelearningZH/simply-simplify-language'}
                      style={{ color: 'black', textDecoration: 'underline' }}
                      target="_blank"
                    >
                      Zürcher Verständlichkeitsindex (ZIX)
                    </a>
                  </h4>
                  <hr />
                  <p className={'description'} dangerouslySetInnerHTML={{ __html: u15y }} />
                  <hr />
                </>
              )}
              {queryResult && (
                <>
                  <h1>{t('answers.answer')}</h1>

                  <hr />
                </>
              )}
              <MyMarkdown markdown={queryResults[0]} />
              {queryResults[0] && queryResults.length > 1 && (
                <CopyToClipboard
                  text={queryResult.split('******')?.[0]}
                  onCopy={() => {
                    setCopied(true)

                    window.setTimeout(() => setCopied(false), 2000)
                  }}
                >
                  <button
                    className={'question__button button button--no-wrap'}
                    style={{ display: 'flex', fontSize: '18px' }}
                  >
                    <span>{copied ? 'Kopiert' : 'Kopieren'}</span> <Copy />
                  </button>
                </CopyToClipboard>
              )}
              <hr />
              {queryResults.length > 1 &&
                inputValue.trim() !== betterText &&
                queryResults
                  .slice(1)
                  .map(qr => (
                    <MyMarkdown
                      markdown={
                        qr +
                        (isLoading
                          ? ' ![](https://chregus.rokka.io/dynamic/crop-width-200-height-25--resize-width-20/o-af-1/b9a927/circles-menu-1.gif)'
                          : '')
                      }
                    ></MyMarkdown>
                  ))}
              {queryResults[0] && queryResults.length > 1 && (
                <>
                  <hr />
                  <h1>Diff:</h1>
                  <hr />
                  <div className={'diff'}>
                    <DiffWords a={inputValue.trim()} b={betterText} />
                  </div>
                </>
              )}
              {queryResults[0] && !isLoading && (
                <>
                  <hr />
                  <h1>Feedback</h1>
                  <ExtendedFeedback
                    providerValue={providerValue}
                    questionRef={questionRef}
                    feedbackRef={feedbackRef}
                    hintRef={hintRef}
                    requestId={requestId}
                    userId={userId}
                    inputValue={inputValue}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </main>
      <Footer languageChooser={false} disclaimer={false} />
    </div>
  )
}
export default TextGen
