import { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import GlobalContext from './GlobalContext.tsx'

export const Footer = (props: { languageChooser?: boolean; disclaimer?: boolean }) => {
  const { t, i18n } = useTranslation()
  const { mainLanguage, langs, setMainLanguage } = useContext(GlobalContext)

  const languageChooser = props.languageChooser !== false
  const disclaimer = props.disclaimer !== false
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__notes">
          <p>
            <Trans i18nKey="footer.credits">
              Powered by
              <a href={'https://www.liip.ch'} target={'_blank'}>
                Liip
              </a>
            </Trans>
          </p>
          <p>{disclaimer && t('footer.disclaimer')}</p>
        </div>
        {languageChooser && (
          <div className="footer__language">
            <label htmlFor="language-switcher" className="sr-only">
              {t('footer.selectLanguage')}
            </label>
            <div className="select">
              <select
                id="language-switcher"
                defaultValue={mainLanguage}
                className="button"
                onChange={e => {
                  setMainLanguage(e.target.value)
                  i18n.changeLanguage(e.target.value)
                }}
              >
                {Object.keys(langs).map(lng => (
                  <option key={lng} value={lng}>
                    {langs[lng].nativeName}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>
    </footer>
  )
}
